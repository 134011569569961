import React, { useEffect, useState } from 'react';
import styles from './Checkbox.module.css';

export default function Checkbox({ data, array, setArray }) {
  const [checked, setCheked] = useState(false);
  const [disable, setDisable] = useState(false);
  const [counter, setCounter] = useState(false);

  setTimeout(() => {
    setCounter(!counter);
  }, 100);

  // const handleOnChange = (e) => {
  //   if (array.includes(e.target.id)) {
  //     const itemToDelete = e.target.id;
  //     const newArr = array.filter((item) => item !== itemToDelete);
  //     setArray(newArr);
  //     setCheked(!counter);
  //   } else {
  //     array.push(e.target.id);
  //     setCounter(!counter);
  //   }

  //   setCheked(!checked);
  // if (checked) {
  //   console.log(e.target.id);
  //   delete array[`${e.target.id}`];
  //   setCheked(false);
  // }
  // if (array.length > 3) {
  // } else {
  //   if (e.target.checked) {
  //     array[`${e.target.id}`] = e.target.id;
  //     console.log(array);
  //   } else {
  //     delete array[`${e.target.id}`];
  //     console.log(array);
  //   }
  //
  // }
  // };

  // useEffect(() => {
  //   if (array?.length > 3) {
  //     array.shift();
  //   }

  //   console.log(array);
  // }, [counter]);

  // =======================================================

  const handleOnChange = (e) => {
    console.log(array);
    if (!array.includes(e.target.id)) {
      if (array.length < 3) {
        array.push(e.target.id);
        const newArr = array;
        setArray(newArr);
        setCheked(true);
        setCounter(!counter);
      } else if (array.length === 3) {
        array.pop();
        array.push(e.target.id);
        const newArr = array;
        setArray(newArr);
        setCheked(true);
        setCounter(!counter);
      }
    } else {
      const itemToDelete = e.target.id;
      const newArr = array.filter((item) => item !== itemToDelete);
      setArray(newArr);
    }
  };

  useEffect(() => {
    if (array.includes(data.title)) {
      console.log(123);
      setCheked(true);
    } else {
      setCheked(false);
    }
  }, [counter]);

  return (
    <label htmlFor={data.title} className={styles.label}>
      {!checked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          className={styles.point}
        >
          <g filter="url(#filter0_i_77_677)">
            <circle cx="12.7129" cy="12.6011" r="12" fill="white" />
          </g>
          <circle cx="12.7129" cy="12.6011" r="11.5" stroke="#0D2B54" />
          <defs>
            <filter
              id="filter0_i_77_677"
              x="0.712891"
              y="0.601074"
              width="24"
              height="28"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_77_677"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          className={styles.point}
        >
          <g filter="url(#filter0_i_36_441)">
            <circle cx="12.7129" cy="12.5" r="12" fill="white" />
          </g>
          <circle cx="12.7129" cy="12.5" r="11.5" stroke="#0D2B54" />
          <g filter="url(#filter1_i_36_441)">
            <circle cx="12.7129" cy="12.5" r="8" fill="#0D2B54" />
          </g>
          <defs>
            <filter
              id="filter0_i_36_441"
              x="0.712891"
              y="0.5"
              width="24"
              height="28"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_36_441"
              />
            </filter>
            <filter
              id="filter1_i_36_441"
              x="4.71289"
              y="4.5"
              width="16"
              height="20"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_36_441"
              />
            </filter>
          </defs>
        </svg>
      )}
      <input
        type="checkbox"
        name={data.title}
        id={data.title}
        onChange={(e) => {
          handleOnChange(e);
        }}
        className={styles.input}
        value={checked}
        disabled={disable}
      />
      <div className={styles.img}>{data.image}</div>
      <p className={styles.text} style={{ color: data.color }}>
        {data.title}
      </p>
    </label>
  );
}
