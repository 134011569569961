import React, { useEffect, useState } from 'react';

export const Sphere = ({ newSvg, id, strong, width, height, index }) => {
  const lastIndex = newSvg.length - 1;

  return (
    <div
      fill={strong}
      int={id}
      className={`bubble-${id}`}
      // проверка если игрок захочет повторно выделить выбранные шарики то массив обнулится
      lanch={
        newSvg.slice(0, lastIndex).some((obj) => obj.id === id) &&
        newSvg.length > 1
          ? '1'
          : '2'
      }
      index={index}
      style={{
        backgroundImage: `url(${strong})`,
        backgroundRepeat: 'no-repeat',
        width: width,
        height: height,
        userSelect: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    ></div>
  );
};
