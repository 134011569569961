import React, { useEffect, useState } from 'react';
import styles from './FinalScreen.module.css';
import back from '../../images/final-back.png';
import { sendEventAnalitic } from '../../utils/api';

export default function FinalScreen() {
  const [id, setId] = useState();
  const [spice, setSpice] = useState();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setId(searchParams.get('user_id'));
    setSpice(searchParams.get('spice_id'));
    console.log(id);
    console.log(spice);
    sendEventAnalitic('passed_all_scenario');
  }, []);

  function closeApp() {
    // sendEventAnalitic('passed_all_scenario');
    const tg = window.Telegram.WebApp;
    tg.close();
    localStorage.clear();
  }

  return (
    <div className={styles.finalScreen}>
      <img src={back} alt="" className={styles.img} />
      <h2 className={styles.title}>Вы молодец!</h2>

      <button className={styles.button} onClick={closeApp}>
        К предложениям
      </button>
    </div>
  );
}
