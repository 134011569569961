import React, { useEffect } from 'react';
import styles from './YourChoiceItem.module.css';

export default function YourChoiceItem({ data }) {
  return (
    <div className={styles.item}>
      <div className={styles.itemImg}>{data?.image}</div>
      <p className={styles.itemName}>{data?.title}</p>
      <p className={styles.itemText}>
        <span className={styles.itemBoldText}>{data?.boldText}</span>
        {data?.text}
        <span className={styles.itemLightText}>{data?.lightText}</span>
      </p>
    </div>
  );
}
