import React, { useEffect, useState } from 'react';
import { Background } from './Components/Background';
import './Styles/Background.css';
import { BackgroundMax } from './Components/BackgroundMax';

function App({ color1, color2, color3, url }) {
  useEffect(() => {
    console.log(window.location.href);
  }, []);
  return (
    <Background color1={color1} color2={color2} color3={color3} url={url} />
  );
}

export default App;
