import React, { useEffect, useRef, useState } from 'react';
import { Sphere } from './Sphere';
import FinalScreen from '../../FinalScreen/FinalScreen';

import opacity from '../aseets/opacity.svg'; // нетрогать

export const Background = ({ color1, color2, color3 }) => {
  const [slots, setSlots] = useState([]); // массив шариков
  const [end, setEnd] = useState(true); // стейт окончании игры
  const [newSvg, setNewSvg] = useState([]); // массив выбраных шариков
  const [openStart, setOpenStart] = useState(false); // проверка на количество выбраных шариков
  const [indexScale, setIndexScale] = useState(''); // проверка на выбранные шары

  // собираем таблицу из 42 слотов
  useEffect(() => {
    let counter = 0;
    const newSlots = Array(44)
      .fill('')
      .map(() => {
        const slot = { id: counter, strong: '', types: 'flex' };
        counter++;
        return slot;
      });

    // разбрасываем шарики по массиву рандомно
    const colors = [color1, color2, color3];

    for (let i = 0; i < newSlots.length; i++) {
      const randomIndex = Math.floor(Math.random() * colors.length);
      newSlots[i].strong = colors[randomIndex];
    }

    // Добавляем код, чтобы каждый пятый объект был белого цвета
    for (let i = 4; i < newSlots.length; i += 5) {
      newSlots[i].strong = 'white';
      newSlots[i].types = 'none';
    }

    // Перекрашиваем последние 8 объектов в прозрачный цвет
    for (let i = newSlots.length - 10; i < newSlots.length; i++) {
      newSlots[i].strong = opacity;
    }

    // обновляем массив
    setSlots(newSlots);
  }, []);

  // обработка нажатии мыши

  const handleSvgMove = (event, id, index) => {
    if (event.nativeEvent.buttons === 1) {
      const svgStyle = event.target.getAttribute('lanch');
      setIndexScale(svgStyle);

      const slot = slots.find((s) => s.id === id);
      const slotStrong = slot ? slot.strong : '';

      const filteredSvg = newSvg.filter((obj) => obj.colored === slotStrong);
      if (filteredSvg.length === 0) {
        if (openStart) {
          const updatedSlots = slots.map((slot) => {
            const foundSvg = newSvg.find((nsvg) => nsvg.id === slot.id);
            if (foundSvg) {
              return {
                ...slot,
                strong: opacity,
              };
            }
            return slot;
          });

          setSlots(updatedSlots);
        }

        setNewSvg([]);
      }

      const newObject = { id: id, colored: slotStrong, ind: index };
      const isIdExists = filteredSvg.some((obj) => obj.id === id);

      if (!isIdExists) {
        setNewSvg((prevState) => [...prevState, newObject]);
      }

      if (newSvg.length >= 2) {
        setOpenStart(true);
      } else {
        setOpenStart(false);
      }

      if (indexScale === '1') {
        setNewSvg([]);
      }

      for (let i = 5; i < 33; i++) {
        if (newSvg.length > 1) {
          const conditions = [
            [i, i - 4],
            [i - 4, i],
            [i, i + 4],
            [i + 4, i],
            [i, i - 6],
            [i - 6, i],
            [i, i + 6],
            [i + 6, i],
          ];

          conditions.forEach(([ind1, ind2]) => {
            if (
              (newSvg[0].ind === ind1 && newSvg[1].ind === ind2) ||
              (newSvg[0].ind === ind2 && newSvg[1].ind === ind1)
            ) {
              setNewSvg([]);
            }
          });
        }
      }
    }
  };

  // обработка нажатии пальца

  const handleTouchMove = (event) => {
    if (event.touches.length === 1) {
      const touch = event.touches[0];
      const targetElement = document.elementFromPoint(
        touch.clientX,
        touch.clientY
      );

      if (targetElement) {
        const svgColor = targetElement.getAttribute('fill');
        const svgId = targetElement.getAttribute('int');
        const numberId = parseInt(svgId);

        const svgStyle = targetElement.getAttribute('lanch');
        const castomIndex = targetElement.getAttribute('index');
        const numberIndex = parseInt(castomIndex);
        setIndexScale(svgStyle);

        const filteredSvg = newSvg.filter((obj) => obj.colored === svgColor);
        if (filteredSvg.length === 0) {
          if (openStart) {
            const updatedSlots = slots.map((slot) => {
              const foundSvg = newSvg.find((nsvg) => nsvg.id === slot.id);
              if (foundSvg) {
                return {
                  ...slot,
                  strong: opacity,
                };
              }
              return slot;
            });

            setSlots(updatedSlots);
          }

          setNewSvg([]);
        }

        const newObject = { id: numberId, colored: svgColor, ind: numberIndex };
        const isIdExists = filteredSvg.some((obj) => obj.id === numberId);

        if (!isIdExists) {
          setNewSvg((prevState) => [...prevState, newObject]);
        }

        if (newSvg.length >= 2) {
          setOpenStart(true);
        } else {
          setOpenStart(false);
        }

        console.log(newSvg);
        if (indexScale === '1') {
          setNewSvg([]);
        }

        for (let i = 5; i < 33; i++) {
          if (newSvg.length > 1) {
            const conditions = [
              [i, i - 4],
              [i - 4, i],
              [i, i + 4],
              [i + 4, i],
              [i, i - 6],
              [i - 6, i],
              [i, i + 6],
              [i + 6, i],
            ];

            conditions.forEach(([ind1, ind2]) => {
              if (
                (newSvg[0].ind === ind1 && newSvg[1].ind === ind2) ||
                (newSvg[0].ind === ind2 && newSvg[1].ind === ind1)
              ) {
                setNewSvg([]);
              }
            });
          }
        }
      }
    }
  };

  const handleEventMove = (event) => {
    if (event.nativeEvent.buttons === 1) {
      slots.map((slot) => {
        if (event.target.className === `bubble-${slot.id}`) {
          return;
        } else if (event.target.className === 'border') {
          setNewSvg([]);
        }
      });
    }
  };

  const handleEventTouch = (event) => {
    if (event.touches.length === 1) {
      const touch = event.touches[0];
      const targetElement = document.elementFromPoint(
        touch.clientX,
        touch.clientY
      );

      if (targetElement) {
        slots.map((slot) => {
          if (targetElement.className === `bubble-${slot.id}`) {
            return;
          } else if (targetElement.className === 'border') {
            setNewSvg([]);
          }
        });
      }
    }
  };

  // если мы отпустили мышь или палец лопает выбранные шарики и очишаем массив
  const handleMouseUp = () => {
    if (openStart) {
      const updatedSlots = slots.map((slot) => {
        const foundSvg = newSvg.find((nsvg) => nsvg.id === slot.id);
        if (foundSvg) {
          return {
            ...slot,
            strong: opacity,
          };
        }
        return slot;
      });

      setSlots(updatedSlots);
    }

    setNewSvg([]);
  };

  // // пересобираем массив если игрок нажал рестарт
  // const Restart = () => {
  //   let counter = 0;
  //   const newSlots = Array(42)
  //     .fill("")
  //     .map(() => {
  //       const slot = { id: counter, strong: "", types: "block" };
  //       counter++;
  //       return slot;
  //     });

  //   // разбрасываем шарики по массиву рандомно
  //   const colors = [aquamarine, darkmagnet, fiolet];

  //   for (let i = 0; i < newSlots.length; i++) {
  //     const randomIndex = Math.floor(Math.random() * colors.length);
  //     newSlots[i].strong = colors[randomIndex];
  //   }

  //   // Добавляем код, чтобы каждый пятый объект был белого цвета
  //   for (let i = 4; i < newSlots.length; i += 5) {
  //     newSlots[i].strong = "white";
  //     newSlots[i].types = "none";
  //   }

  //   // Перекрашиваем последние 8 объектов в прозрачный цвет
  //   for (let i = newSlots.length - 8; i < newSlots.length; i++) {
  //     newSlots[i].strong = opacity;
  //   }

  //   // обновляем массив
  //   setSlots(newSlots);
  //   setEnd(true);
  // };

  // падение шариков

  const swapSlots = () => {
    setSlots((prevSlots) => {
      const newSlots = [...prevSlots];
      const swapIfNeeded = (index1, index2) => {
        if (newSlots[index2].strong === opacity) {
          [newSlots[index1], newSlots[index2]] = [
            newSlots[index2],
            newSlots[index1],
          ];
        }
      };

      for (let i = 0; i <= 25; i += 5) {
        for (let j = i; j <= i + 3; j++) {
          swapIfNeeded(j, j + 5);
        }
      }

      // проверка на все возможные комбинацци тут самая жара на которой я застрял целый день
      let isStrongAdjacent = false;

      const checkAdjacent = (start, end) => {
        for (let i = start; i < end; i++) {
          if (newSlots[i].strong !== opacity) {
            if (
              (i < end &&
                newSlots[i - 1].strong === newSlots[i].strong &&
                newSlots[i - 5].strong === newSlots[i].strong) ||
              (newSlots[i + 1].strong === newSlots[i].strong &&
                newSlots[i + 5].strong === newSlots[i].strong) ||
              (newSlots[i - 1].strong === newSlots[i].strong &&
                newSlots[i + 5].strong === newSlots[i].strong) ||
              (newSlots[i + 1].strong === newSlots[i].strong &&
                newSlots[i - 5].strong === newSlots[i].strong)
            ) {
              isStrongAdjacent = true;
              break;
            }
          }
        }
      };

      checkAdjacent(5, 39);

      for (let i = 10; i < 14; i++) {
        if (newSlots[i].strong !== opacity) {
          if (i < 14 - 1 && newSlots[i + 1].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      for (let i = 15; i < 19; i++) {
        if (newSlots[i].strong !== opacity) {
          if (i < 19 - 1 && newSlots[i + 1].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      for (let i = 20; i < 24; i++) {
        if (newSlots[i].strong !== opacity) {
          if (i < 24 - 1 && newSlots[i + 1].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      for (let i = 25; i < 29; i++) {
        if (newSlots[i].strong !== opacity) {
          if (i < 29 - 1 && newSlots[i + 1].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      for (let i = 30; i < 34; i++) {
        if (newSlots[i].strong !== opacity) {
          if (i < 34 - 1 && newSlots[i + 1].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      for (let i = 10; i < 30; i = i + 5) {
        if (newSlots[i].strong !== opacity) {
          if (i > 10 && newSlots[i - 5].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
          if (i < 30 - 1 && newSlots[i + 5].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      for (let i = 11; i < 31; i = i + 5) {
        if (newSlots[i].strong !== opacity) {
          if (i > 11 && newSlots[i - 5].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
          if (i < 31 - 1 && newSlots[i + 5].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      for (let i = 12; i < 32; i = i + 5) {
        if (newSlots[i].strong !== opacity) {
          if (i > 12 && newSlots[i - 5].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
          if (i < 32 - 1 && newSlots[i + 5].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      for (let i = 13; i < 33; i = i + 5) {
        if (newSlots[i].strong !== opacity) {
          if (i > 13 && newSlots[i - 5].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
          if (i < 33 - 1 && newSlots[i + 5].strong === newSlots[i].strong) {
            isStrongAdjacent = true;
            break;
          }
        }
      }

      // сохраняем результат в стейт
      setEnd(isStrongAdjacent);

      return newSlots;
    });
  };

  useEffect(() => {
    // перезапускаем функцию для визуализации падения шаров и переобнавления все возможных комбинаций игры
    const interval = setTimeout(() => {
      swapSlots();
    }, 80);

    return () => {
      clearInterval(interval);
    };
  }, [slots]);

  return (
    <div className="gameContainer">
      <p className="title">
        Соедините шарики одного цвета и полностью очистите экран
      </p>

      <div className="game">
        <div
          className="border"
          index="3"
          onMouseMove={handleEventMove}
          onTouchMove={handleEventTouch}
        >
          <div className="game-phone">
            {slots.map((slot, index) => (
              <div
                onMouseMove={(event) => handleSvgMove(event, slot.id, index)}
                onTouchMove={handleTouchMove}
                onTouchStart={handleTouchMove}
                key={slot.id}
                onMouseUp={handleMouseUp}
                onTouchEnd={handleMouseUp}
                className="game-slot"
                // черная линия при выбирании шариков для красоты
                style={{
                  background:
                    newSvg.some((obj) => obj.id === slot.id) &&
                    slot.strong !== opacity
                      ? '#b6e1ff'
                      : '#E9FBFF',
                  display: slot.types,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Sphere
                  newSvg={newSvg}
                  id={slot.id}
                  index={index}
                  strong={slot.strong}
                  width={window.screen.width > 275 ? '9vh' : '7vh'}
                  height={window.screen.width > 275 ? '9vh' : '7vh'}
                />
              </div>
            ))}
          </div>
        </div>

        {!end && <FinalScreen />}
      </div>
    </div>
  );
};
