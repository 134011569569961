import React, { useEffect, useState, useRef } from 'react';
import styles from './Choice.module.css';
import { heets, fiits } from '../../utils/tasties.js';
import { render } from '@testing-library/react';
import Checkbox from '../Checkbox/Checkbox.jsx';

export default function Choice({ openYourChoice, array, setArray }) {
  const ref = useRef(null);
  const [scroll, setScroll] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const scrollHeight = ref.current.scrollHeight;

    setScrollHeight(scrollHeight);
  }, [ref]);

  const procent = () => {
    if (scroll != 0) {
      const procent = (scroll / scrollHeight) * 100;
      const procent2 = (height / 100) * procent;
      const result = scroll + procent2;
      return result;
    } else {
      const procent = 0;
      const procent2 = (height / 100) * procent;
      const result = scroll + procent2;
      return result;
    }
  };

  const scrollDown = () => {
    const el = document.getElementById('container');

    el.scrollTop += 70;
  };
  const scrollUp = () => {
    const el = document.getElementById('container');

    el.scrollTop -= 70;
  };

  return (
    <div className={styles.choice}>
      <h2 className={styles.title}>
        Выберите 3 любимых вкуса, чтобы определить, какими шариками вы будете
        играть
      </h2>
      <div className={styles.container}>
        <div action="" className={styles.formContainer}>
          <button
            className={styles.formButton}
            onClick={() => {
              scrollUp();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="m12 6.586-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z" />
            </svg>
          </button>

          <div className={styles.form} id="container" ref={ref}>
            <p className={styles.formTitle}>HEETS</p>
            {heets.map((heet) => {
              return <Checkbox data={heet} array={array} setArray={setArray} />;
            })}
            <p className={styles.formTitle}>Fiit</p>
            {fiits.map((fiit) => {
              return <Checkbox data={fiit} array={array} setArray={setArray} />;
            })}
          </div>

          <button
            className={styles.formButton}
            onClick={() => {
              scrollDown();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
            </svg>
          </button>
        </div>
      </div>
      <button
        className={styles.button}
        type="submit"
        onClick={() => {
          if (array.length === 3) {
            openYourChoice();
          }
        }}
      >
        Дальше
      </button>
    </div>
  );
}
