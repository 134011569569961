import React, { useState, useEffect } from 'react';
import styles from './Main.module.css';
import Guide from '../Guide/Guide';
import Choice from '../Choice/Choice';
import YourChoice from './../YourChoice/YourChoice';

import App from '../123/App';
import FinalScreen from '../FinalScreen/FinalScreen';
import red from '../123/aseets/red.svg'; // красный
import blue from '../123/aseets/blue.svg'; // синий
import green from '../123/aseets/green.svg'; // зеленый
import aqua from '../123/aseets/aqua.svg'; // водяной
import aquamarine from '../123/aseets/aquamarine.svg'; // аквамарин
import brick from '../123/aseets/brick.svg'; // кирпичный
import brown from '../123/aseets/brown.svg'; // коричневый
import darkLime from '../123/aseets/darkLime.svg'; // темно лаймовый
import darkmagnet from '../123/aseets/darkmagnet.svg'; // темноое магнета
import darkyellow from '../123/aseets/darkyellow.svg'; // темно желтый
import fiolet from '../123/aseets/fiolet.svg'; // фиолетовый
import gold from '../123/aseets/gold.svg'; // золотой
import lightblue from '../123/aseets/lightblue.svg'; // ярко синий
import lightbrown from '../123/aseets/lightbrown.svg'; // ярко коричневый
import lime from '../123/aseets/lime.svg'; // лайм
import maggnet from '../123/aseets/maggnet.svg'; // магнетта
import orange from '../123/aseets/orange.svg'; // оранжевый
import orangered from '../123/aseets/orangered.svg'; // красно оранжевый
import pink from '../123/aseets/pink.svg'; // розовый
import yellow from '../123/aseets/yellow.svg'; // желтый

export default function Main() {
  const [guide, setGuide] = useState(true);
  const [choice, setChoice] = useState(false);
  const [yourChoice, setYourChoice] = useState(false);
  const [game, setGame] = useState(false);
  const [final, setFinal] = useState(true);
  const [array, setArray] = useState([]);
  const [color1, setColor1] = useState();
  const [color2, setColor2] = useState();
  const [color3, setColor3] = useState();

  const openChoice = () => {
    setGuide(false);
    setYourChoice(false);
    setChoice(true);
  };

  const openYourChoice = () => {
    setChoice(false);
    setYourChoice(true);
  };

  const openGame = () => {
    setColors(array);
    setYourChoice(false);
    setGame(true);
  };

  const setColors = (array) => {
    console.log(array[0]);
    switch (array[0]) {
      case 'BRONZE SELECTION':
        setColor1(brick);
        break;
      case 'AMBER SELECTION':
        setColor1(orange);
        break;
      case 'SLATE SELECTION':
        setColor1(blue);
        break;
      case 'GOLD SELECTION':
        setColor1(darkyellow);
        break;
      case 'RUBY FUSE':
        setColor1(pink);
        break;
      case 'AMARELO FUSE':
        setColor1(lime);
        break;
      case 'SATIN FUSE':
        setColor1(yellow);
        break;
      case 'TURQUOISE SELECTION':
        setColor1(aqua);
        break;
      case 'GREEN ZING':
        setColor1(darkLime);
        break;
      case 'PURPLE WAVE':
        setColor1(maggnet);
        break;
      case 'SUMMER  BREEZE':
        setColor1(orangered);
        break;
      case 'ARBOR':
        setColor1(green);
        break;
      case 'SUN':
        setColor1(red);
        break;
      case 'twilight':
        setColor1(fiolet);
        break;
      case 'REGULAR DEEP':
        setColor1(brown);
        break;
      case 'REGULAR':
        setColor1(lightblue);
        break;
      case 'TROPIC':
        setColor1(gold);
        break;
      case 'SPRING':
        setColor1(lightbrown);
        break;
      case 'MARINE':
        setColor1(aquamarine);
        break;
      case 'VIOLA':
        setColor1(darkmagnet);
        break;

      default:
        setColor1(blue);
    }
    switch (array[1]) {
      case 'BRONZE SELECTION':
        setColor2(brick);
        break;
      case 'AMBER SELECTION':
        setColor2(orange);
        break;
      case 'SLATE SELECTION':
        setColor2(blue);
        break;
      case 'GOLD SELECTION':
        setColor2(darkyellow);
        break;
      case 'RUBY FUSE':
        setColor2(pink);
        break;
      case 'AMARELO FUSE':
        setColor2(lime);
        break;
      case 'SATIN FUSE':
        setColor2(yellow);
        break;
      case 'TURQUOISE SELECTION':
        setColor2(aqua);
        break;
      case 'GREEN ZING':
        setColor2(darkLime);
        break;
      case 'PURPLE WAVE':
        setColor2(maggnet);
        break;
      case 'SUMMER  BREEZE':
        setColor2(orangered);
        break;
      case 'ARBOR':
        setColor2(green);
        break;
      case 'SUN':
        setColor2(red);
        break;
      case 'twilight':
        setColor2(fiolet);
        break;
      case 'REGULAR DEEP':
        setColor2(brown);
        break;
      case 'REGULAR':
        setColor2(lightblue);
        break;
      case 'TROPIC':
        setColor2(gold);
        break;
      case 'SPRING':
        setColor2(lightbrown);
        break;
      case 'MARINE':
        setColor2(aquamarine);
        break;
      case 'VIOLA':
        setColor2(darkmagnet);
        break;

      default:
        setColor2(blue);
    }
    switch (array[2]) {
      case 'BRONZE SELECTION':
        setColor3(brick);
        break;
      case 'AMBER SELECTION':
        setColor3(orange);
        break;
      case 'SLATE SELECTION':
        setColor3(blue);
        break;
      case 'GOLD SELECTION':
        setColor3(darkyellow);
        break;
      case 'RUBY FUSE':
        setColor3(pink);
        break;
      case 'AMARELO FUSE':
        setColor3(lime);
        break;
      case 'SATIN FUSE':
        setColor3(yellow);
        break;
      case 'TURQUOISE SELECTION':
        setColor3(aqua);
        break;
      case 'GREEN ZING':
        setColor3(darkLime);
        break;
      case 'PURPLE WAVE':
        setColor3(maggnet);
        break;
      case 'SUMMER  BREEZE':
        setColor3(orangered);
        break;
      case 'ARBOR':
        setColor3(green);
        break;
      case 'SUN':
        setColor3(red);
        break;
      case 'twilight':
        setColor3(fiolet);
        break;
      case 'REGULAR DEEP':
        setColor3(brown);
        break;
      case 'REGULAR':
        setColor3(lightblue);
        break;
      case 'TROPIC':
        setColor3(gold);
        break;
      case 'SPRING':
        setColor3(lightbrown);
        break;
      case 'MARINE':
        setColor3(aquamarine);
        break;
      case 'VIOLA':
        setColor3(darkmagnet);
        break;

      default:
        setColor3(blue);
    }
  };
  return (
    <div className={styles.main}>
      {guide && <Guide openChoice={openChoice} />}
      {choice && (
        <Choice
          openYourChoice={openYourChoice}
          setArray={setArray}
          array={array}
        />
      )}
      {yourChoice && (
        <YourChoice
          openGame={openGame}
          openChoice={openChoice}
          array={array}
          setArray={setArray}
        />
      )}

      {game && <App color1={color1} color2={color2} color3={color3} />}
    </div>
  );
}
