import React, { useEffect } from 'react';
import { useState } from 'react';
import styles from './Guide.module.css';
import gif from '../../images/guide-gif.gif';
import gif1 from '../../images/guide-gif1.gif';

export default function Guide({ openChoice }) {
  return (
    <>
      <div className={styles.guide}>
        <h2 className={styles.title}>В поисках вкуса</h2>

        <p className={styles.text}>
          Ваша цель – соединить шарики одного цвета от 2 штук.
          <br />
          <br />
          Как только вы объедините все — экран очистится. Чем больше шариков,
          тем ближе победа!
          <br />
          <br />
          Соединять шарики можно снизу-вверх и слева-направо:
        </p>
        <div className={styles.gifContainer}>
          <div className={styles.gif2}>
            <img src={gif} alt="" className={styles.gif} />
          </div>
          <div className={styles.gif3}>
            <img src={gif1} alt="" className={styles.gif1} />
          </div>
        </div>

        <button className={styles.button} onClick={openChoice}>
          Дальше
        </button>
      </div>
    </>
  );
}
