import React, { useEffect, useState } from 'react';
import styles from './YourChoice.module.css';
import { fiits, heets } from '../../utils/tasties';
import YourChoiceItem from '../YourChoiceItem/YourChoiceItem';

export default function YourChoice({ openGame, openChoice, array, setArray }) {
  const [resultArr, setResultArr] = useState([]);
  useEffect(() => {
    console.log(window.location.href);
  }, []);

  useEffect(() => {
    const result = [];
    array.forEach((e) => {
      console.log(e);

      const find = heets.find((item) => item.title === e);
      if (find) {
        result.push(find);
      }

      console.log(result);
    });
    array.forEach((e) => {
      console.log(e);
      const find = fiits.find((item) => item.title === e);
      if (find) {
        result.push(find);
      }

      console.log(result);
    });

    setResultArr(result);
  }, []);

  return (
    <div className={styles.yourchoice}>
      <div className={styles.container}>
        <div className={styles.header}>
          <button
            className={styles.headerButton}
            onClick={() => {
              setArray([]);
              openChoice();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="24"
              viewBox="0 0 22 24"
              fill="none"
            >
              <path
                d="M0.939341 10.9427C0.353554 11.5285 0.353554 12.4782 0.939341 13.064L10.4853 22.6099C11.0711 23.1957 12.0208 23.1957 12.6066 22.6099C13.1924 22.0241 13.1924 21.0744 12.6066 20.4886L4.12132 12.0033L12.6066 3.51805C13.1924 2.93226 13.1924 1.98251 12.6066 1.39672C12.0208 0.810938 11.0711 0.810938 10.4853 1.39672L0.939341 10.9427ZM22 10.5033L2 10.5033V13.5033L22 13.5033V10.5033Z"
                fill="black"
              />
            </svg>
          </button>
          <p className={styles.headerText}>ВАШ ВЫБОР:</p>
        </div>
        {resultArr.map((item) => {
          return <YourChoiceItem data={item} />;
        })}

        <button className={styles.button} onClick={openGame}>
          Играем
        </button>
      </div>
    </div>
  );
}
